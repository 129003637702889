<template>
  <div class="kota">
    <h1 class="mb-5">Data Kota</h1>
    <div class="row">
      <!-- Card -->
      <div class="col-md-12">
        <div class="card">
          <h5
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            Daftar Kota
            <button
              @click="showFormModal = true"
              class="btn btn-sm btn-primary"
            >
              <i class="bi bi-plus-circle"></i> Tambah Kota
            </button>
          </h5>
          <div class="card-body table-responsive">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Cari"
                aria-label="Cari"
                aria-describedby="button-addon2"
                v-model="search"
                @keypress.enter="searchData"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="searchData"
              >
                Cari
              </button>
            </div>
            <transition name="fade">
              <div class="text-center" v-if="isLoading">
                <div class="spinner-grow" role="status"></div>
              </div>
              <Table
                v-else
                :headers="headers"
                :items="kota"
                @edit="editItem"
                @delete="confirmDelete"
              />
            </transition>
            <Pagination
              v-if="kota.length"
              :currentPage="currentPage"
              :totalData="totalData"
              :totalPage="totalPage"
              @nextPage="nextPage"
              @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal konfirmasi hapus -->
    <delete-modal
      :showModal="showDeleteModal"
      :id="selectedKotaId"
      @closeModal="closeDeleteModal"
      @destroy="destroyData"
    />

    <!-- Modal tambah/edit -->
    <form-modal
      :showModal="showFormModal"
      :editedIndex="editedIndex"
      :editedId="editedId"
      :editedItem="editedItem"
      @closeModal="closeForm"
      @save="saveForm"
      :provinsi="provinsi"
    />
  </div>
</template>

<script>
import { kotaService, provinsiService } from "@/services";
import DeleteModal from "./components/DeleteModal.vue";
import FormModal from "./components/FormModal.vue";
import Table from "./components/Table.vue";
import Pagination from "./components/Pagination.vue";

export default {
  components: {
    Table,
    FormModal,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      // Search query
      search: "",
      limit: 10,
      sort: "updatedAt",
      order: "desc",

      // Pagination
      totalData: 0,
      currentPage: 1,

      // Loading State
      isLoading: false,

      // Data Array
      headers: [
        "Provinsi",
        "Nama",
        "Tanggal Dibuat",
        "Tanggal Diubah",
        "Tindakan",
      ],
      kota: [],
      provinsi: [],

      // Modal state
      showDeleteModal: false,
      showFormModal: false,
      selectedKotaId: 0,

      // Default form state
      editedIndex: -1,
      editedId: 0,
      editedItem: {
        propinsi_id: "",
        nama: "",
      },
      defaultItem: {
        propinsi_id: "",
        nama: "",
      },
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  methods: {
    // Search & Load
    searchData() {
      this.isLoading = true;
      this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    loadData(limit, search, page, sort, order) {
      kotaService.getAll(limit, search, page, sort, order).then((data) => {
        this.kota = data.rows;
        this.totalData = data.count;
        this.currentPage = page;
      });
      this.isLoading = false;
    },
    async loadProvinsi() {
      const provinsi = await provinsiService.getAll();
      this.provinsi = provinsi.rows;
    },

    // Pagination
    previousPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage - 1,
        this.sort,
        this.order
      );
    },
    nextPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage + 1,
        this.sort,
        this.order
      );
    },

    // Modal
    editItem(item) {
      this.editedIndex = this.kota.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showFormModal = true;
    },
    async saveForm() {
      const { nama, propinsi_id } = this.editedItem;
      if (this.editedIndex > -1) {
        // Update data
        try {
          await kotaService.update({ nama, propinsi_id }, this.editedItem.id);
          this.closeForm();
          await this.searchData();
        } catch (error) {
          this.$toast.error(
            (error.response && error.response.data.errors[0].message) ||
              error.message
          );
        }
      } else {
        // Insert new data
        try {
          await kotaService.create(this.editedItem);
          this.closeForm();
          await this.searchData();
        } catch (error) {
          this.$toast.error(
            (error.response && error.response.data.errors[0].message) ||
              error.message
          );
        }
      }
    },
    closeForm() {
      this.showFormModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    confirmDelete(id) {
      this.showDeleteModal = true;
      this.selectedKotaId = id;
    },
    destroyData(id) {
      kotaService
        .destroy(id)
        .then(() => {
          this.$toast.success(`Data berhasil dihapus`);
          this.showDeleteModal = false;
          this.selectedKotaId = 0;
          this.searchData();
        })
        .catch((error) => {
          this.$toast.error(
            (error.response && error.response.data.message) || error.message
          );
        });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.loadData(
      this.limit,
      this.search,
      this.currentPage,
      this.sort,
      this.order
    );
    await this.loadProvinsi();
  },
};
</script>

<style scoped>
.kota {
  padding: 3rem 1.5rem;
}
</style>
